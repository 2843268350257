@use "../common/vars.scss" as *;
@use "../mixins/container.scss" as *;

:root {
  /**
   * Базовые переменные
   */
  --saturation: 40%;
  --hue-primary: 260;
  --hs-primary: var(--hue-primary), var(--saturation);
  --hue-secondary: 280;
  --saturation-secondary: 66%;
  --hs-secondary: var(--hue-secondary), var(--saturation);

  /**
   * Базовые цвета
   */
  --color-b1: hsl(var(--hue-primary), 15%, 15%);
  --color-b2: hsl(var(--hue-primary), 20%, 20%);
  --color-b3: hsl(var(--hue-primary), 30%, 30%);
  --color-b4: hsl(var(--hue-primary), 50%, 50%);
  --color-b5: hsl(var(--hue-primary), 50%, 70%);
  --color-b6: hsl(var(--hue-primary), 55%, 88%);
  --color-b7: hsl(var(--hue-primary), 90%, 95%);
  --color-b8: hsl(var(--hue-primary), 100%, 100%);
  --color-primary: hsl(var(--hs-primary), 52%);
  --color-primary-hover: hsl(var(--hs-primary), 63%);
  --color-primary-active: hsl(var(--hs-primary), 41%);
  --color-secondary: hsl(var(--hs-secondary), 52%);
  --color-secondary-hover: hsl(var(--hs-secondary), 63%);
  --color-secondary-active: hsl(var(--hs-secondary), 41%);
  --color-title: var(--color-b1);
  --color-transparent: hsla(var(--hs-primary), 50%, .2);
  --color-transparent-accent: hsla(var(--hs-primary), 60%, .1);
  --color-transparent-hover: hsla(var(--hue-primary), 10%, 50%, .3);
  --color-transparent-active: hsla(var(--hue-primary), 10%, 50%, .4);
  --color-transparent-medium: hsla(var(--hue-primary), 25%, 25%, .5);
  --color-transparent-dark: hsla(var(--hue-primary), 10%, 10%, .7);

  /**
   * Базовые анимации
   */
  --animation-multiplier: 3;
  --animation-duration: calc(var(--animation-multiplier) * .375s);
  --animation-duration-fast: calc(var(--animation-multiplier) * .125s);
  --animation-duration-slow: calc(var(--animation-multiplier) * .5s);
  --animation-theme: var(--animation-duration-fast) color ease-in-out, var(--animation-duration-slow) background-color ease-in-out, var(--animation-duration-slow) border-color ease-in-out;

  /**
   * Шрифты
   */
  --font-family: futura, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-alt: system-ui, -apple-system, "Segoe UI", "Helvetica Neue", arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-size: 1rem;
  --line-height: 1.5;

  /* светлая/тёмная тема */
  --theme-fg: var(--color-b1);
  --theme-bg: var(--color-b8);
  --theme-bg-alt: var(--color-b5);
  --theme-bg-secondary: var(--color-b7);
  --theme-bg-secondary-semi-transparent: hsla(var(--hue-primary), 90%, 95%, .5);
  --theme-bg-secondary-semi-transparent2: hsla(var(--hue-primary), 90%, 95%, .9);
  --theme-border: var(--color-b6);
  --theme-border-focus: var(--color-b5);
  --theme-transparent: hsla(var(--hue-primary), 11%, 100%, 0);
  --theme-control: var(--color-primary);

  /* для кнопки */
  --color-danger: #ffbebe;
  --color-red: #c22f3d;
  --hue-red: 10;

  /* для свайпера */
  --swiper-navigation-color: var(--theme-border-focus);

  color-scheme: light dark;

  @media (prefers-color-scheme: dark) {
    .body--light {
      --theme-fg: var(--color-b1);
      --theme-bg: var(--color-b8);
      --theme-bg-alt: var(--color-b5);
      --theme-bg-secondary: var(--color-b7);
      --theme-bg-secondary-semi-transparent: hsla(var(--hue-primary), 90%, 95%, .5);
      --theme-bg-secondary-semi-transparent2: hsla(var(--hue-primary), 90%, 95%, .9);
      --theme-border: var(--color-b6);
      --theme-border-focus: var(--color-b5);
      --theme-transparent: hsla(var(--hue-primary), 11%, 100%, 0);
      --theme-control: var(--color-primary);

      /* для кнопки */
      --color-danger: #ffbebe;
    }

    --theme-fg: var(--color-b6);
    --theme-bg: var(--color-b2);
    --theme-bg-alt: var(--color-b3);
    --theme-bg-secondary: var(--color-b1);
    --theme-bg-secondary-semi-transparent: hsla(var(--hue-primary), 15%, 15%, .3);
    --theme-bg-secondary-semi-transparent2: hsla(var(--hue-primary), 15%, 15%, .9);
    --theme-border: var(--color-b3);
    --theme-border-focus: var(--color-b4);
    --theme-transparent: hsla(var(--hue-primary), 11%, 20%, 0);
    --theme-control: var(--color-primary);

    /* для кнопки */
    --color-danger: #724848;
  }

  .body--dark {
    --theme-fg: var(--color-b6);
    --theme-bg: var(--color-b2);
    --theme-bg-alt: var(--color-b3);
    --theme-bg-secondary: var(--color-b1);
    --theme-bg-secondary-semi-transparent: hsla(var(--hue-primary), 15%, 15%, .3);
    --theme-bg-secondary-semi-transparent2: hsla(var(--hue-primary), 15%, 15%, .9);
    --theme-border: var(--color-b3);
    --theme-border-focus: var(--color-b4);
    --theme-transparent: hsla(var(--hue-primary), 11%, 20%, 0);
    --theme-control: var(--color-primary);

    /* для кнопки */
    --color-danger: #724848;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 { font-weight: 400; }

a {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;

  &:hover,
  &:focus {
    color: var(--color-primary-hover);
    text-decoration: underline;
    svg { fill: var(--color-primary-hover); }
  }

  &:active {
    color: var(--color-primary-active);
    svg { fill: var(--color-primary-active); }
  }
}

svg {
  vertical-align: text-bottom;
  fill: currentcolor;
}

label { cursor: pointer; }
input[type="number"]:not(.num) { appearance: textfield; }

input[type="number"]:not(.num)::-webkit-inner-spin-button,
input[type="number"]:not(.num)::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

[hidden] { display: none; }
