@use "../mixins/container.scss" as *;

.admin__container { @include container; }

.admin__links {
  padding-left: 0;
  font-size: 1.5rem;
  list-style-type: none;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    vertical-align: bottom;
  }
}
