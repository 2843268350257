// Размеры
$widths: (
  xs: 320px,
  sm: 376px,
  md: 576px,
  lg: 1024px,
  xl: 1264px,
);

// Отступы
$gap: 140px;
$gap-sm: 1rem;
$gap-md: 1.5rem;
$gap-lg: 2rem;

// padding-bottom секции main
$gap-bottom-main: 5rem;
