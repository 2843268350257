@use "../common/vars.scss" as *;
@use "../mixins/media.scss" as *;

@mixin container {
  box-sizing: border-box;
  min-width: map-get($widths, xs);
  padding-right: $gap-sm;
  padding-left: $gap-sm;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;

  @include media-min(md) {
    min-width: map-get($widths, md);
    padding-right: $gap-md;
    padding-left: $gap-md;
  }
  @include media-min(lg) {
    min-width: map-get($widths, lg);
    padding-right: $gap-lg;
    padding-left: $gap-lg;
  }
  @include media-min(xl) { max-width: map-get($widths, xl); }
}
