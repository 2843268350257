@use "../mixins/media.scss" as *;

@mixin btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 1.5rem;
  font-family: var(--font-family);
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border: none;

  &:disabled {
    cursor: progress;
    background-color: var(--theme-border);
  }

  > svg {
    margin-right: .7em;
    margin-left: -.7em;
  }

  > span + svg {
    margin-right: -4px;
    margin-left: 14px;
  }

  @include media-min(xl) { justify-content: initial; }
}
@mixin btn-primary {
  @include btn;

  color: white;
  background-color: var(--color-primary);

  &:hover,
  &:focus,
  &:active { color: white; }

  &:hover {
    text-decoration: none;
    background-color: var(--color-primary-hover);
  }
  &:focus { outline: 3px solid var(--color-transparent-hover); }
  &:active { background-color: var(--color-primary-active); }
}
@mixin btn-primary-lg {
  @include btn-primary;

  font-size: 1.2rem;
  font-weight: 300;
}
@mixin btn-secondary {
  @include btn;

  color: var(--color-secondary);
  background-color: var(--color-transparent);

  &:hover,
  &:focus,
  &:active { color: var(--color-secondary); }
  &:hover { background-color: var(--theme-bg-secondary-semi-transparent); }
  &:active { background-color: var(--theme-bg-secondary-semi-transparent2); }
}
@mixin btn-dark {
  @include btn;

  color: white;
  background-color: hsla(var(--hue-blue), 29%, 66%, .25);

  &:hover,
  &:focus,
  &:active { color: white; }
  &:hover { background-color: hsla(var(--hue-blue), 29%, 66%, .2); }
  &:active { background-color: hsla(var(--hue-blue), 29%, 66%, .3); }
}
@mixin btn-border {
  @include btn;

  padding: 11px 29px 7px;
  color: var(--color-secondary);
  background-color: var(--theme-transparent);
  border: .25rem solid var(--color-secondary);
  border-radius: 1rem;

  &:hover,
  &:focus,
  &:active { color: var(--color-secondary); }
  &:hover { background-color: hsla(var(--hue-primary), 29%, 66%, .2); }
  &:active { background-color: hsla(var(--hue-primary), 29%, 66%, .3); }
}
@mixin btn-dark-lg {
  @include btn-dark;

  font-size: 1.5rem;
  font-weight: 300;

  > svg {
    margin-top: .25rem;
    opacity: .7;
  }
}
@mixin btn-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: var(--color-primary);

  > svg {
    width: 24px;
    height: 24px;
    color: white;
  }
}
@mixin btn-preview {
  @include btn-ico;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
