@font-face {
  font-family: futura;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/futura-light.woff2") format("woff2"), url("../fonts/futura-light.woff") format("woff");
}
@font-face {
  font-family: futura;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/futura-book.woff2") format("woff2"), url("../fonts/futura-book.woff") format("woff");
}
@font-face {
  font-family: futura;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/futura-medium.woff2") format("woff2"), url("../fonts/futura-medium.woff") format("woff");
}
@font-face {
  font-family: futura;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/futura-demi.woff2") format("woff2"), url("../fonts/futura-demi.woff") format("woff");
}
