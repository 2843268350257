.progress-bar2 {
  width: min(100svw - 2rem, 13rem);
  height: 1rem;
  overflow: hidden;
  cursor: pointer;
  background: #777;
  border-radius: 100vh;

  > div,
  > progress[value] {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #d9a800, #df5000);
    border-radius: inherit;
    transition: transform 300ms cubic-bezier(.75, 0, .25, 1);
    transform: scaleX(0);
    transform-origin: left center;
  }

  > progress[value] {
    --color: yellow; /* the progress color */
    --background: indianred; /* the background color */

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 200px;
    margin: 0 10px;
    border-radius: 10em;
    background: var(--background);
  }
}
