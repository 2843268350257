@use "../common/vars.scss" as *;

.body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: 300;
  line-height: var(--line-height);
  text-size-adjust: 100%;

  // -webkit-tap-highlight-color: rgba(black, 0);
}

.body__inner {
  display: flex;
  flex-direction: column;
  min-width: map-get($widths, xs);
  min-height: 100vh;
  color: var(--theme-fg);
  background-color: var(--theme-bg);
}
